<template>
  <div>
    <!-- download92724CDBA550E8FEE1F2624C6DB09BB3.swf -->
    <!-- picB35CA758BADF02A39BEC2378D960FBDC.jpg -->
    <img class="img-large rounded" src="@/assets/news-network/challenge-build-more-fun.jpg">
    <p>
      This explosive LEGO Universe graphic has fired up you fans! Now is the time to turn that creative energy into inspiration in the <router-link :to="{ name: 'creation-lab-list', params: {challengeId: '8B5F9142-A4DD-41EC-8DF4-95152305C81C'} }">Creation Lab</router-link>!
    </p>
    <p>
      Overcome the latest creative challenge by making a mini-game based on the graphic-- and hurry! According to Professor Brickkeeper, "Inspiration is what we need to battle the foes of fun! Without your creativity we could all be kaput!"
    </p>
  </div>
</template>
